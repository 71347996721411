@import "assets/styles/main";

.account {
  display: flex;
  flex-direction: column;
  padding-top: 3.2rem;

  .account-form {
    display: flex;
    flex-direction: column;

    &__title {
      font-size: 3.6rem;
      font-family: Poppins-Medium, sans-serif;
      color: $color-dark-black;
      margin-bottom: 2.4rem;
    }

    &__digit {
      color: $color-secondary;
      font-family: Roboto;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 2.4rem;
    }

    .error__container {
      font-size: 1.8rem;
      color: $color-danger;
      margin-bottom: 3.2rem;
    }

    .error__message1 {
      font-size: 1.8rem;
      font-family: Roboto-Regular, sans-serif;
      color: $color-danger;
      margin-bottom: 3.2rem;
    }

    .error__message2 {
      font-size: 1.8rem;
      font-family: Roboto-Regular, sans-serif;
      color: $color-danger;
    }

    .error__message3 {
      font-size: 1.8rem;
      font-family: Roboto-Regular, sans-serif;
      color: $color-danger;
    }

    &--input {
      width: 100%;
      height: 5.2rem;
      display: flex;
      justify-content: center;
      margin-bottom: 3.2rem;

      div {
        div:not(:nth-child(3)) {
          .separator {
            display: none;
          }
        }
      }
    }

    &--input-field {
      font-size: 3rem;
      background-color: inherit;
      border: 0.1rem solid $color-black;
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-radius: 0;
      margin: 0 0.2rem;
      outline: 0;
      width: 1.4em;
    }

    &--container-field {
      background-color: #f8f9ff;
      padding: 1rem 5rem;
      width: 40rem;
      justify-content: center;
      align-items: end;
    }

    &__button1 {
      margin-bottom: 3.2rem;
      width: 30.4rem;
      height: 5.2rem;
      font-size: 1.6rem;
      font-family: Poppins-Medium, sans-serif;
    }

    @include styles-for-device-size(sm) {
      &__digit {
        margin-bottom: 1.6rem;
      }

      &--input {
        margin-bottom: 2.4rem;
      }

      &__button1 {
        margin-bottom: 2.4rem;
      }
    }
  }

  .link {
    color: $color-secondary;
    font-family: Poppins;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
  }

  .link:hover {
    cursor: pointer;
  }

  @include styles-for-device-size(sm) {
    padding-top: 3rem;

    .account-form {
      &__title {
        color: $color-primary;
        font-size: 2.4rem;
        font-weight: 500;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        margin-bottom: 0;
      }

      &__digit {
        font-size: 1.6rem;
        padding: 0.8rem;
      }

      .error__message {
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
        width: 78%;
        margin-left: 11%;
      }

      .error__message1 {
        align-self: center;
        width: 90%;
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
      }

      .error__message3 {
        align-self: center;
        width: 90%;
        font-size: 1.6rem;
      }

      .error__container {
        display: flex;
        width: 81%;
        margin-bottom: 2.4rem;
        flex-direction: column;
        align-self: center;
      }

      &--container-field {
        width: 30.4rem;
        height: 6rem;
      }
    }
  }
}
