@import "assets/styles/main";

.profile-section {
  .applicant-label {
    margin: 3.2rem 0 1.6rem;
  }

  .applicant-demographics {
    margin-bottom: 3.2rem;
  }

  .applicant-name {
    font-size: 1.8rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    color: $color-dark-black;
    white-space: nowrap;
    width: 40rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.8rem;
  }

  .applicant-email {
    font-family: Roboto, sans-serif;
    font-size: 1.6rem;
    color: $color-dark-black;
    margin: 0.4rem 0 0;
    white-space: nowrap;
    width: 40.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .spouse-addresses,
  .borrower-addresses {
    display: flex;
    gap: 1.6rem;
    margin-top: 3.2rem;

    .address {
      min-width: 40.5rem;
    }
  }

  .profile-applicant,
  .profile-spouse-co-applicant {
    padding-bottom: 3.2rem;
  }

  .profile-applicant {
    .applicant-name {
      margin-bottom: 1.6rem;
    }
  }

  .profile-co-applicant {
    .applicant-demographics {
      display: flex;
      gap: 1.6rem;
    }

    .co-borrower-applicant-demographics {
      display: flex;
      margin-bottom: 3.2rem;

      &__section1 {
        display: flex;
        gap: 1.6rem;
      }

      &__section2 {
        display: flex;
        align-items: end;
      }
    }
  }

  .no-co-applicants {
    p {
      margin-bottom: 1.6rem;
      font-family: Roboto, sans-serif;
      font-size: 1.4rem;
      font-style: italic;
      color: $color-gray-bg;
    }

    &__title {
      margin-bottom: 3.2rem;
    }
  }

  .section-border:not(:last-of-type) {
    border-bottom: 0.1rem solid $color-light-gray-bg;
  }
}

@include styles-for-device-size(md) {
  .profile-section {
    .addresses {
      flex-direction: column;
    }

    .address:not(:last-child) {
      margin-bottom: 2.4rem;
    }

    .borrower-addresses,
    .spouse-addresses,
    .co-borrower-applicant-demographics__section1 {
      flex-direction: column;

      .address {
        min-width: 30%;
      }
    }

    .borrower-addresses {
      margin-top: 0;
      gap: 0;
    }

    .spouse-addresses {
      gap: 0;
    }

    .applicant-demographics {
      margin-bottom: 2.4rem;
    }

    .applicant-email,
    .applicant-name {
      width: 100%;
    }

    .profile-co-applicant {
      .applicant-demographics {
        display: flex;
        flex-direction: column;
        width: inherit;
        row-gap: 0;
        column-gap: 0;

        .applicant-demographic {
          margin-bottom: 2.4rem;
          margin-right: 0;
        }
      }
    }
  }
}