@import "assets/styles/main";

.tooltip3 {
  position: absolute;
  top: 12rem;
  left: 37rem;
  z-index: 2;
}

@include styles-for-device-size(sm) {
  .tooltip3 {
    top: 17rem;
    left: 10%;

    .guide-tooltip-arrow {
      .guide-tooltip-container {
        height: 16.8rem;
      }
    }
  }
}
