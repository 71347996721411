@import "assets/styles/main";

.navbar {
  display: flex;
  justify-content: space-between;

  &__links {
    display: flex;
  }

  .link {
    font-size: 1.4rem;
    text-decoration: none;
    color: $color-white;
    padding: 2.1rem 3.1rem 2.3rem;
    font-family: Poppins-SemiBold, sans-serif;
    text-transform: uppercase;

    .guide-tooltip-arrow {
      position: absolute;
      top: 150%;
      left: 30%;

      .guide-tooltip-container {
        height: 18.8rem;
      }

      .guide-tooltip-bottom-layer {
        .guide-tooltip-number,
        .guide-tooltip-content {
          font-size: 1.2rem;
        }

        .guide-tooltip-button {
          margin-top: 5rem;
        }
      }
    }
  }

  .link.active {
    border-top: 0.4rem solid $color-secondary;
    font-weight: 600;
  }

  .link.highlight {
    border: .6rem solid $color-light-blue-secondary;
    z-index: 2;
  }

  .active {
    color: $color-primary;
    background-color: $color-white;
  }

  .hidden {
    visibility: hidden;
  }

  &__btn {
    color: $color-white;
    cursor: pointer;
    display: none;
  }
}

@include styles-for-device-size(sm) {
  .navbar-container {
    .logout {
      &__icon {
        margin-left: 0;
      }
    }
  }

  .navbar {
    position: absolute;
    top: 6.4rem;
    left: 50%;
    min-height: 118rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: $color-white;
    z-index: 2;
    animation: mymove 1s forwards;

    .link {
      display: flex;
      margin-left: 2.7rem;
      color: $color-primary;
      padding: 0;
      margin-bottom: 2.2rem;
      text-decoration: none;

      &__icon {
        font-size: 1.6rem;
        margin-right: 0.8rem;
      }

      &__text {
        font-size: 1.4rem;
      }
    }

    .link.active {
      border-top: none;
      font-weight: 0;
    }

    .lines {
      border-top: 0.1rem solid $color-light-gray-bg;
      margin-bottom: 2.1rem;
    }

    &__links {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 5.6rem;
    }

    &__btn {
      display: block;
    }

    &__close-btn {
      color: $color-primary;
      position: absolute;
      top: 0.9rem;
      left: 1.5rem;
    }

    &--actions {
      transform: none;
    }

    @keyframes mymove {
      50% {
        left: 50%;
      }
    }
  }
}